/**
 * Returns UA with potentially bypassing Google Lighthouse's audit warning.
 */
export function getUserAgent(): string {
  // @ts-ignore: TS2551: Property 'userAgentData' does not exist on type 'Navigator'
  const uaData = navigator.userAgentData;
  if (uaData?.brands) {
    return uaData.brands.map((item) => item.brand + '/' + item.version).join(' ');
  }
  return navigator.userAgent;
}

/**
 * Checks whether user is on IOS.
 */
export function isIOS(): boolean {
  return /iP(hone|od|ad)/.test(getUserAgent());
}

/**
 * Sets maximum-scale of viewport meta to 1.0
 */
export function addMaximumScaleToMetaViewport() {
  const el = document.querySelector('meta[name=viewport]');

  if (el !== null) {
    let content = el.getAttribute('content');
    let re = /maximum-scale=[0-9.]+/g;

    if (re.test(content)) {
      content = content.replace(re, 'maximum-scale=1.0');
    } else {
      content = [content, 'maximum-scale=1.0'].join(', ');
    }

    el.setAttribute('content', content);
  }
}

/**
 * Disables IOS auto zooming when taping on input fields with font-size < 16px
 * by setting maximum-scale=1.0 to viewport meta.
 */
export function disableIosTextFieldZoom() {
  if (isIOS()) {
    console.log('isIOS');
    addMaximumScaleToMetaViewport();
  }
}
