import get from 'just-safe-get';
import { dynamicImportWithRetry } from '../lib/dynamicImportWithRetry';
import { timeout } from '../lib/timeout';

export function inputTel(Alpine) {
  Alpine.data('inputTel', inputTelComponent);

  function inputTelComponent({ errorParam = 'errorMessage' } = {}) {
    return {
      value: '',
      isInputFocused: false,
      errorMessage: '',
      get isEmpty() {
        return (this.value ?? '').toString().length === 0;
      },
      get isFocused() {
        return this.isInputFocused || !this.isEmpty;
      },
      get error() {
        return get(this, errorParam, '');
      },
      async init() {
        await timeout(2000);
        const { default: Inputmask } = await dynamicImportWithRetry(() => import('inputmask'));
        new Inputmask({
          mask: '(+7 999 999-99-99)|(+99999999999[9{1,3}])',
          showMaskOnHover: false,
        }).mask(this.$refs.input);
      },
      label: {
        [':class']() {
          return {
            'is-focused': this.isFocused,
            'has-error': this.error,
          };
        },
      },
      input: {
        ['@focus']() {
          this.isInputFocused = true;
        },
        ['@blur']() {
          this.isInputFocused = false;
        },
      },
    };
  }
}
