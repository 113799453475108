import { wpRESTGet } from '../lib/wpREST';
import { SearchParamsOption } from 'ky';

type TemplatesResponse = {
  html: string;
};

export async function getTemplate(
  name: string,
  params: SearchParamsOption
): Promise<TemplatesResponse> {
  return wpRESTGet<TemplatesResponse>(`nf/v1/templates/${name}/`, params);
}
